import React from "react"
import { SEO } from "../components"
import { Section, Hero, About, Services, Contact } from "../components"
import Layout from "../layouts"

const IndexPage = (): JSX.Element => {
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <main>
          <Section p="20px" bg="#fff">
            <Hero />
          </Section>
          <Section p="0" bg="#fff">
            <About />
          </Section>
          <Section p="0" bg="var(--secondary)">
            <Services />
          </Section>
          <Section p="0" bg="#fff">
            <Contact id="contact" />
          </Section>
        </main>
      </Layout>
    </>
  )
}

export default IndexPage
